
// import {Link} from "react-router-dom";
export const Navbar = () => {
    return (
        <div>
            {/* <Link to="/"> Home </Link>
            <Link to="frameinfo"> Frame Info </Link>
            <Link to="about"> About </Link> */}
        </div>
    );
};