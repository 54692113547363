import React from 'react';

// Create a context to import images dynamically
const imageContext = require.context('./images', false, /\.(jpg|jpeg|png)$/);

const MyImageComponent = ({ itemName }) => {
    // Function to generate image path
    const getImagePath = (name) => {
        let formattedName = name.replace(/`/g, '');
        if (formattedName.endsWith('frame')) {
            formattedName = formattedName.slice(0, -5); // Remove "frame"
        }
        formattedName = formattedName.replace(/\s+/g, '');

        try {
            return imageContext(`./frame-${formattedName}.jpg`); // Dynamic import
        } catch (err) {
            console.error('Image not found:', err);
            return ''; // Return a fallback or empty string
            //return '/path/to/default-image.jpg'; // Fallback image
        }
    };

    const imagePath = getImagePath(itemName);

    return (
        <img src={imagePath} alt={itemName} />
    );
};

export default MyImageComponent;
